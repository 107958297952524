/* eslint-disable react/no-unescaped-entities */
"use client"; // Error components must be Client Components

import Link from "next/link";
import { useEffect } from "react";
import BizRootLayout from "./_layout/RootLayout";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <BizRootLayout>
      <div className="flex min-h-screen items-center p-4 text-center md:justify-center md:px-10">
        <div className="space-y-4">
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl">
            Oops! Something went wrong.
          </h1>
          <p className="mx-auto max-w-[500px] text-gray-500 dark:text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Don't worry, we're on it. We'll fix it as soon as possible. Please
            try again later.
          </p>
          <div className="flex flex-col justify-center gap-2 min-[400px]:flex-row">
            <Link
              className="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 border-gray-200 bg-white px-8 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
              href="/"
            >
              Go back
            </Link>
            <Link
              className="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 border-gray-200 bg-white px-8 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
              href="mailto:talencat001@gmail.com"
            >
              Contact Us
            </Link>
          </div>

          <div className="pt-20">
            <p>Error details</p>
            <p className="mx-auto max-w-[500px] text-sm text-gray-500 dark:text-gray-400">
              {error.message}
            </p>
          </div>
        </div>
      </div>
    </BizRootLayout>
  );
}
